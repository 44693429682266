import Vue from "vue";
import { extend } from "vee-validate";
import {
  alpha_spaces,
  email,
  max,
  max_value,
  min,
  min_value,
  regex,
  required,
} from "vee-validate/dist/rules";
import VueI18Next from "@panter/vue-i18next";
import i18next from "i18next";

Vue.use(VueI18Next);

const i18n = new VueI18Next(i18next);

extend("checkbox", {
  validate(value) {
    return value === true;
  },
  message(field, values) {
    return i18n.t("this-field-is-required");
  },
});

extend("max_value", {
  ...max_value,
  message(field, values) {
    return i18n.t(
      "you-dont-have-enough-resources-on-your-account-for-this-transaction"
    );
  },
});

extend("alpha_spaces", {
  ...alpha_spaces,
  message(field, values) {
    return i18n.t("latin-characters-only");
  },
});

extend("max_value_agent", {
  ...max,
  message(field, values) {
    return i18n.t("this-number-must-be-shorter-than-10-digits");
  },
});

extend("max_value_percentage", {
  ...max_value,
  message(field, values) {
    return i18n.t("invalid-percentage");
  },
});

extend("min_value_telephone", {
  ...min,
  message(field, values) {
    return i18n.t("this-field-is-not-valid");
  },
});

extend("max_value_telephone", {
  ...max,
  message(field, values) {
    return i18n.t("this-field-is-not-valid");
  },
});

extend("min_value", {
  ...min_value,
  message: `{_field_}`,
});

extend("regex", {
  ...regex,
  message: "{_field_}",
});

extend("email", {
  ...email,
  message(field, values) {
    return i18n.t("this-field-is-not-valid");
  },
});

extend("required", {
  ...required,
  message(field, values) {
    return i18n.t("this-field-is-required");
  },
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message(field, values) {
    return i18n.t("passwords-do-not-match");
  },
});

// checking if the birth year + 18 is more than current year
extend("minAgeRestriction", {
  validate: (value) => {
    var currentYear = new Date().getFullYear();
    // getting the last set of numbers after / which will represent the birth year
    var birthDate = value.split("/").pop();
    birthDate = parseInt(birthDate) + 18;
    if (birthDate > currentYear) {
      return false;
    } else {
      return true;
    }
  },
  message(field, values) {
    return i18n.t("you-have-to-be-over-18-to-continue");
  },
});

extend("maxAgeRestriction", {
  validate: (value) => {
    var birthDate = value.split("/").pop();
    birthDate = parseInt(birthDate);
    if (birthDate < 1920) {
      return false;
    } else {
      return true;
    }
  },
  message(field, values) {
    return i18n.t("invalid-format");
  },
});

// checking if date year is larger than curent year
extend("futureDate", {
  validate: (value) => {
    var currentYear = new Date().getFullYear();
    // getting the last set of numbers after
    var date = value.split("/").pop();
    date = parseInt(date);
    if (date > currentYear) {
      return false;
    } else {
      return true;
    }
  },
  message(field, values) {
    return i18n.t("please-enter-a-valid-date");
  },
});

// validation for open live account / first step - "Are you a US citizen or a US resident? We need to know this for tax purposes." field must have a "No" as answer
extend("UScitizen", {
  validate: (value) => {
    if (value === "yes") {
      return false;
    } else {
      return true;
    }
  },
  message(field, values) {
    return i18n.t("unfortunately-we-are-unable-to-offer-you-a-live-account");
  },
});

// on icmmena client cant continue with oppening of the live account if the answer is "no"
extend("HighAssets", {
  validate: (value) => {
    if (value === "yes") {
      return true;
    } else {
      return false;
    }
  },
  message(field, values) {
    return i18n.t("unfortunately-we-are-unable-to-offer-you-a-live-account");
  },
});

// this validation will always return false and we will use it only when phone input $event returns isValid = false
extend("validatePhone", {
  validate: (value) => {
    return false;
  },
  message(field, values) {
    return i18n.t("this-field-is-not-valid");
  },
});

extend("thirteenDigits", {
  validate: (value) => {
    if (value.length !== 13) {
      return false;
    } else {
      return true;
    }
  },
  message(field, values) {
    return i18n.t("The code must be 13 digits long");
  },
});

extend("twelveDigitsPostCode", {
  validate: (value) => {
    return value.length <= 12;
  },
  message(field, values) {
    return i18n.t("Postcode should contain 12 alphanumeric characters at most");
  },
});

extend("sixteenDigits", {
  validate: (value) => {
    if (value.length !== 16) {
      return false;
    } else {
      return true;
    }
  },
  message(field, values) {
    return i18n.t("The code must be 16 digits long");
  },
});

extend("elevenDigits", {
  validate: (value) => {
    if (value.length !== 11) {
      return false;
    } else {
      return true;
    }
  },
  message(field, values) {
    return i18n.t("The code must be 11 digits long");
  },
});

extend("thirtyDigits", {
  validate: (value) => {
    if (value.length > 30) {
      return false;
    } else {
      return true;
    }
  },
  message(field, values) {
    return "Ensure this field has no more than 30 characters.";
  },
});

extend("sixtyDigits", {
  validate: (value) => {
    if (value.length > 60) {
      return false;
    } else {
      return true;
    }
  },
  message(field, values) {
    return "Ensure this field has no more than 60 characters.";
  },
});

extend("cardNumber", {
  validate: (value) => {
    var result = true;
    value = value.split("/");
    var pattern = /^\d{2}$/;

    if (value[0] < 1 || value[0] > 12) {
      result = false;
      return false;
    }

    if (!pattern.test(value[0]) || !pattern.test(value[1])) {
      result = false;
      return false;
    }

    if (value[2]) {
      result = false;
      return false;
    }

    if (result) {
      return true;
    }
  },
  message(field, values) {
    return "Please enter a valid date in MM/YY format.";
    // return i18n.t('please-enter-a-valid-date-in-format');
  },
});
