/**
 * Set an auth request
 *
 * @param state
 */
import { INITIAL_STATE } from "@/store/state";

export function AUTH_REQUEST(state) {
  state.status = "loading";
}

/**
 * Set an auth success
 *
 * @param state
 * @param payload
 */
export function AUTH_SUCCESS(state, payload) {
  state.status = "success";
  state.userData = payload[0];
  state.token = payload[1];
}

/**
 * Set an auth error
 *
 * @param state
 * @param err
 */

export function AUTH_ERROR(state, err) {
  state.status = err;
}

/**
 * Logout
 *
 * @param state
 */
export function LOGOUT(state) {
  state.status = "";
  state.token = "";
  //   delete all local storage, cookies, etc.
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
  localStorage.clear();
  sessionStorage.clear();
  state.show_next_step = 0;
  state.authentication.showNextStep = 0;

  state = Object.assign(state, INITIAL_STATE);

  return state;
}

/**
 * Signup Data
 *
 * @param state
 * @param payload
 */
export function SET_SIGNUP_DATA(state, payload) {
  state.authentication.signupData = payload;
}

export function SET_TRADING_PLATFORMS(state, payload) {
  state.authentication.tradingPlatforms = payload;
}

export function SET_DEMO_TRADING_PLATFORMS(state, payload) {
  state.authentication.tradingDemoPlatforms = payload;
}

export function SET_SUITABLE_STATUS(state, payload) {
  state.suitable = payload;
}

export function SET_MOBILE_LOGIN_LINK(state, payload) {
  state.mobileLoginLink = payload;
}

/**
 * Set an authentication step
 *
 * @param state
 * @param payload
 */

export function SHOW_NEXT_STEP(state, payload) {
  state.authentication.showNextStep = payload;
}

export function RESET_STEP_NUMBER(state) {
  state.authentication.showNextStep = 0;
}

export function SET_PROFILE(state, payload) {
  state.profile = payload;
}

export function SET_PROFILE_OPTIONS(state, payload) {
  state.profileOptions = payload;
}

export function SET_USER_DATA(state, payload) {
  state.userData = payload;
}

export function SET_ACCOUNT_DATA(state, payload) {
  state.accountData = payload;
}

export function SET_DEMO_BACKEND_ACCOUNTS(state, payload) {
  state.demoBackendAccounts = payload;
}

export function SET_LIVE_BACKEND_ACCOUNTS(state, payload) {
  state.liveBackendAccounts = payload;
}

export function SET_LIVE_ACCOUNT_DATA(state, payload) {
  state.liveAccounts = payload;
}

export function SET_SYSTEM_CURRENCIES(state, payload) {
  state.systemCurrencies = payload;
}

export function SET_PAYMENT_HISTORY(state, payload) {
  state.paymentHistory = payload;
}

export function SET_TRANSACTION_HISTORY(state, payload) {
  state.transactionHistory = payload;
}

export function SET_UPLOAD_PRESETS(state, payload) {
  state.uploadPresets = payload;
}

export function SET_UPLOADED_DOCUMENTS(state, payload) {
  state.uploadedDocuments = payload;
}

export function SET_DEPOSIT_METHODS(state, payload) {
  state.depositMethods = payload;
}

export function SET_PAYMENTS_GATEWAY(state, payload) {
  state.paymentsGateway = payload;
}

export function SET_BANK_ACCOUNT_FORMS(state, payload) {
  state.bankAccountForms = payload;
}

export function SET_BANK_ACCOUNTS(state, payload) {
  state.bankAccounts = payload;
}

export function SHOW_ADD_BANK_ACCOUNT(state, payload) {
  state.showAddNewBankAccount = payload;
}

export function SET_WITHDRAWAL_METHODS(state, payload) {
  state.withdrawalMethods = payload;
}

export function SET_AVAILABLE_CARDS(state, payload) {
  state.availableCards = payload;
}

export function SET_CARD_REQUESTS(state, payload) {
  state.cardRequests = payload;
}

export function SET_SELECTED_ACCOUT_ID(state, payload) {
  state.selectedAccountId = payload;
}

export function RESET_SELECTED_ACCOUT_ID(state) {
  state.selectedAccountId = "";
}

export function SET_ANNUAL_CHECK(state, payload) {
  state.annualCheck = payload;
}

export function SET_GCC_DEMO_POPUP(state, payload) {
  state.gccShopPopupAfterDemo = payload;
}

// IB PORTAL

export function SET_IB_PROFILE(state, payload) {
  state.ibProfile = payload;
}

export function SET_COUNTRIES(state, payload) {
  state.countries = payload;
}
export function SET_USER_COUNTRY(state, payload) {
  state.userCountry = payload;
}

export function SET_IB_SUMMARY(state, payload) {
  state.ibSummary = payload;
}

export function SET_TRACKING_LINKS_WHITELABEL(state, payload) {
  state.trackingLinksWhitelabels = payload;
}

export function SET_TRACKING_LINKS(state, payload) {
  state.trackingLinks = payload;
}

export function SET_CLIENT_LIST(state, payload) {
  state.clientList = payload;
}

export function SET_SUB_IB_CLIENT_LIST(state, payload) {
  state.subIbClientList = payload;
}

export function SET_SUB_IB_LIST(state, payload) {
  state.subIbList = payload;
}

export function SET_IB_UPLOAD_DOCUMENTS(state, payload) {
  state.ibUploadDocuments = payload;
}

export function SET_IB_PAYMENT_HISTORY(state, payload) {
  state.ibPaymentHistory = payload;
}

export function SET_PAYMENTS_INFO(state, payload) {
  state.paymentsInfo = payload;
}

export function SET_IB_WITHDRAWALS_METHODS(state, payload) {
  state.ibWithdrawalMethods = payload;
}

export function SET_WITHDRAW_REQUESTS(state, payload) {
  state.withdrawRequests = payload;
}

export function SET_LEAD_FORMS(state, payload) {
  state.leadForms = payload;
}

export function CHANGE_SHOW_SIDEBAR(state) {
  state.showSidebar = !state.showSidebar;
}

//Languages

export function CHANGE_LANGUAGE(state, payload) {
  state.lang = payload;
}

export function SET_EN_LANG(state, payload) {
  state.en = payload;
}

// SSO token for DX trader mobile integration
export function SET_SSO_TOKEN(state, payload) {
  state.ssoToken = payload;
}

export function SET_ICM_ACADEMY_MODAL(state, payload) {
  state.showIcmAcademyModal = payload;
}

export function SET_ICM_POPUP(state, payload) {
  state.showICMPopup = payload;
}

export function SET_ICM_CAPITAL_SUITABLE_MODAL(state, payload) {
  state.icmCapitalSuitableModal = payload;
}

export function SET_BROKEREE_WIDGET_TOKEN(state, payload) {
  state.brokereeWidgetToken = payload;
}

export function SET_ICM_ZENDESK_VERSION(state, payload) {
  state.zendeskRegular = payload;
}

export function SET_LIMITED_STATE_UPGRADE_MODAL(state, payload) {
  state.limitedStateUpgradeModal = payload;
}

export function SET_LIMITED_USER_MESSAGE(state, payload) {
  state.limitedUserMessage = payload;
}

export function SET_PROOF_OF_ID_MODAL(state, payload) {
  state.proofOfIdModal = payload;
}
export function SET_ICM_PROOF_MODAL(state, payload) {
  state.showICMProofModal = payload;
}

export function SET_STATUS_CHANGE_MESSAGE_MODAL(state, payload) {
  state.statusChangeMessageModal = payload;
}

export function SET_ALERT_NAV(state, payload) {
  state.showAlertNav = payload;
}

export function SET_ALERT_NAV_MESSAGE(state, payload) {
  state.alertNavMessage = payload;
}
