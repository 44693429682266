export function is_logged_in(state) {
  return !!state.token;
}

export function auth_token(state) {
  return state.token;
}

export function auth_status(state) {
  return state.status;
}

export function get_signup_data(state) {
  return state.authentication.signupData;
}

export function get_trading_platforms(state) {
  return state.authentication.tradingPlatforms;
}

export function get_demo_trading_platforms(state) {
  return state.authentication.tradingDemoPlatforms;
}

export function get_suitable(state) {
  return state.suitable;
}

export function get_profile(state) {
  return state.profile;
}

export function get_profile_options(state) {
  return state.profileOptions;
}

export function get_user_data(state) {
  return state.userData;
}

export function show_next_step(state) {
  return state.authentication.showNextStep;
}

export function get_account_data(state) {
  return state.accountData;
}

export function get_demo_backend_accounts(state) {
  return state.demoBackendAccounts;
}

export function get_live_backend_accounts(state) {
  return state.liveBackendAccounts;
}

export function get_live_accounts(state) {
  return state.liveAccounts;
}

export function get_system_currencies(state) {
  return state.systemCurrencies;
}

export function get_payment_history(state) {
  return state.paymentHistory;
}

export function get_transaction_history(state) {
  return state.transactionHistory;
}

export function get_upload_presets(state) {
  return state.uploadPresets;
}

export function get_uploaded_documents(state) {
  return state.uploadedDocuments;
}

export function get_deposit_methods(state) {
  return state.depositMethods;
}

export function get_payments_gateway(state) {
  return state.paymentsGateway;
}

export function get_bank_account_forms(state) {
  return state.bankAccountForms;
}

export function get_bank_accounts(state) {
  return state.bankAccounts;
}

export function show_new_bank_account(state) {
  return state.showAddNewBankAccount;
}

export function get_withdrawal_methods(state) {
  return state.withdrawalMethods;
}

export function get_available_cards(state) {
  return state.availableCards;
}

export function get_card_requests(state) {
  return state.cardRequests;
}

export function get_lang(state) {
  return state.lang;
}

export function get_selected_account_id(state) {
  return state.selectedAccountId;
}

export function get_annual_check(state) {
  return state.annualCheck;
}

export function get_gcc_demo_popup(state) {
  return state.gccShopPopupAfterDemo;
}

// IB PORTAL

export function get_ib_profile(state) {
  return state.ibProfile;
}

export function get_countries(state) {
  return state.countries;
}
export function get_user_country(state) {
  return state.userCountry;
}

export function get_ib_summary(state) {
  return state.ibSummary;
}

export function get_tracking_links(state) {
  return state.trackingLinks;
}

export function get_tracking_links_whitelabel(state) {
  return state.trackingLinksWhitelabels;
}

export function get_client_list(state) {
  return state.clientList;
}

export function get_sub_ib_client_list(state) {
  return state.subIbClientList;
}

export function get_sub_ib_list(state) {
  return state.subIbList;
}

export function get_ib_upload_documents(state) {
  return state.ibUploadDocuments;
}

export function get_ib_payment_history(state) {
  return state.ibPaymentHistory;
}

export function get_payments_info(state) {
  return state.paymentsInfo;
}

export function get_ib_withdrawal_methods(state) {
  return state.ibWithdrawalMethods;
}

export function get_withdraw_requests(state) {
  return state.withdrawRequests;
}

export function get_lead_forms(state) {
  return state.leadForms;
}

export function get_show_sidebar(state) {
  return state.showSidebar;
}

export function get_mobile_login_link(state) {
  return state.mobileLoginLink;
}

//Languages

export function en(state) {
  return state.en;
}

// SSO token for DX trader mobile integration
export function get_sso_token(state) {
  return state.ssoToken;
}

export function get_icm_academy_modal(state) {
  const targetDate = new Date("2023-11-30T15:00:00+02:00"); // Belgrade time is UTC+2
  const currentDate = new Date();
  return currentDate < targetDate && state.showIcmAcademyModal;
}

export function get_icm_popup(state) {
  return state.showICMPopup;
}
export function get_icm_proof_modal(state) {
  return state.showICMProofModal;
}

export function get_icm_capital_suitable_modal(state) {
  return state.icmCapitalSuitableModal;
}

export function get_icm_zendesk_version(state) {
  return state.zendeskRegular;
}

// brokeree widget token
export function get_widget_token(state) {
  return state.brokereeWidgetToken;
}

export function get_limited_state_upgrade_modal(state) {
  return state.limitedStateUpgradeModal;
}

export function get_limited_user_message(state) {
  return state.limitedUserMessage;
}

export function get_proof_of_id_modal(state) {
  return state.proofOfIdModal;
}

export function get_status_change_message_modal(state) {
  return state.statusChangeMessageModal;
}

export function get_alert_nav(state) {
  return state.showAlertNav;
}
export function get_alert_nav_message(state) {
  return state.alertNavMessage;
}

export function get_trading_days(state) {
  return state.profile.profile?.limits?.trading_days;
}
